
import LsEditor from '@/components/editor.vue'
import lsDialog from '@/components/ls-dialog.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { apiCsjRewardSettingDetails, apiCsjRewardSettingSet } from '@/api/setting'
@Component({
    components: {
        MaterialSelect,
        lsDialog,
        LsEditor
    }
})
export default class CsjRewardSettingEdit extends mixins() {
    /** S Data **/

    list: any = {}

    /** E Data **/

    /** S Methods **/

    onSubmit() {
        apiCsjRewardSettingSet({
            ...this.list,
            aid: 0,
        })
            .then(() => {
                this.detail()
                this.$message.success('修改成功!')
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    // 详情
    detail() {
        return apiCsjRewardSettingDetails({
            aid: 0,
        })
            .then(res => {
                this.list = res
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    async created() {
        this.loadData()
    }
    /** E Life Cycle **/

    loadData() {
        return Promise.all([
            this.detail(),
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }
}
